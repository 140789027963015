import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'greeley-photos',
  templateUrl: 'greeley-photos.page.html',
  styleUrls: ['greeley-photos.page.scss'],
})
export class GreeleyPhotosPage {

  constructor(private titleService: Title, private router: Router) {}

  ionViewDidEnter() {
    this.titleService.setTitle('Greeley Photography');
  }

  routeHome() {
    this.router.navigate(['/home']);
  }

}
